@font-face {
  font-family: Liberation Mono;
  font-style: italic;
  src: url(/assets/fonts/liberation-mono/LiberationMono-Italic.ttf);
  font-display: swap;
}

@font-face {
  font-family: Liberation Mono;
  src: url(/assets/fonts/liberation-mono/LiberationMono-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: Liberation Mono;
  font-weight: bold;
  src: url(/assets/fonts/liberation-mono/LiberationMono-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Liberation Mono;
  font-weight: bold;
  font-style: italic;
  src: url(/assets/fonts/liberation-mono/LiberationMono-BoldItalic.ttf);
  font-display: swap;
}

