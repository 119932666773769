@import url(reset.css);
@import url(assets/fonts/liberation-mono/liberation-mono.css);
@import url(assets/fonts/source-sans-pro/source-sans-pro.css);

:root {
  --primary-color-1: #2E3532;
  --primary-color-2: #8B2635;
  --secondary-color-1: #E0E2DB;
  --secondary-color-2: #D2D4C8;
  --secondary-color-3: #D3EFBD;
  --secondary-color-4: #000000;
}

html {
  scroll-behavior: smooth;
}

a {
  color: var(--secondary-color-3);
}
